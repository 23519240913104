import { render, staticRenderFns } from "./CobrancaParcialProcedimento.vue?vue&type=template&id=37de2728&scoped=true"
import script from "./CobrancaParcialProcedimento.vue?vue&type=script&lang=js"
export * from "./CobrancaParcialProcedimento.vue?vue&type=script&lang=js"
import style0 from "./CobrancaParcialProcedimento.vue?vue&type=style&index=0&id=37de2728&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37de2728",
  null
  
)

export default component.exports