<template>
  <v-form @submit.prevent="onBeforeSubmit" ref="formRef" >
    <!-- <prev>{{currentItem}}</prev> -->
    <div @submit.prevent="onBeforeSubmit" >
      <v-row dense>
        <v-col cols="2">
          <v-select
            :items="cobrancaParcialOptions"
            label="Nº da Cobrança Parcial"
            outlined
            v-model="currentItem.n_cobranca_parcial"
            required
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-text-field
            label="Data Inicial"
            outlined
            v-model="dataInicial"
            type="date"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            label="Data Final"
            outlined
            v-model="dataFinal"
            type="date"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="2" class="mt-3 ml-5">
          <v-btn @click="filtrarEvolucoes" color="primary" :loading="isLoadingLocal">Filtrar Evoluções</v-btn>
        </v-col>
        <v-col cols="2" class="mt-3" >
          <ConfirmButton color="success" :loading="loading">
            Salvar
          </ConfirmButton>
        </v-col> 
      </v-row>
      <v-row dense class="align-center h">
        <v-col >
          <div class="text-black text-center" >
            Resultado da Pesquisa:
            <br>
            <span v-if="isLoadingLocal" class="text-red">
              Processando, por favor aguarde...
            </span>
            <span v-else class="blod_color">
              {{ evolucoesFiltradas.length }} evoluções selecionados : 
              {{ evolucoesFiltradas.map(evolucao => evolucao.id).join(', ') }}
            </span>
          </div>
        </v-col>
      </v-row>
        
    </div>
    </v-form>
</template>
  
<script>
import api from "../../../http";
import ConfirmButton from '../../ConfirmButton.vue';
import '../../../assets/css/styles.css';

export default {
  name: 'ProcedureForm',
  components: { ConfirmButton },
  props: {
    onSubmit: Function,
    currentItem: Object,
    loading: Boolean
  },
  data() {
    return {
      evolucao: [],
      evolucoesFiltradas: [],
      dataInicial: null,
      dataFinal: null,
      isLoadingLocal: this.loading,
      cobrancaParcialOptions: this.getCobrancaParcialOptions()
    };
  },
  methods: {
    getCobrancaParcialOptions() {
      // Cria uma array com os valores de n_cp_1 a n_cp_4 que não são nulos
      const options = [
        this.currentItem.n_cp_1,
        this.currentItem.n_cp_2,
        this.currentItem.n_cp_3,
        this.currentItem.n_cp_4
      ].filter(value => value !== null);
      return options;
    },
    updateDates() {
      // Atualiza as datas com base no número de cobrança parcial selecionado
      const { n_cp_1, n_cp_2, n_cp_3, n_cp_4, data_inicio_cp_1, data_fim_cp_1, data_inicio_cp_2, data_fim_cp_2, data_inicio_cp_3, data_fim_cp_3, data_inicio_cp_4, data_fim_cp_4 } = this.currentItem;

      if (this.currentItem.n_cobranca_parcial === n_cp_1) {
        this.dataInicial = data_inicio_cp_1;
        this.dataFinal = data_fim_cp_1;
      } else if (this.currentItem.n_cobranca_parcial === n_cp_2) {
        this.dataInicial = data_inicio_cp_2;
        this.dataFinal = data_fim_cp_2;
      } else if (this.currentItem.n_cobranca_parcial === n_cp_3) {
        this.dataInicial = data_inicio_cp_3;
        this.dataFinal = data_fim_cp_3;
      } else if (this.currentItem.n_cobranca_parcial === n_cp_4) {
        this.dataInicial = data_inicio_cp_4;
        this.dataFinal = data_fim_cp_4;
      } else {
        this.dataInicial = null;
        this.dataFinal = null;
      }
    },
    onBeforeSubmit() {
      // Se houver validação no formulário Vuetify, execute-a
      if (this.$refs.formRef) {
        const isValid = this.$refs.formRef.validate();
        if (!isValid) {
          this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
          return;
        }
      }

      // Verificar se `Nº de Cobrança Parcial` está preenchido corretamente
      if (!this.currentItem.n_cobranca_parcial || isNaN(this.currentItem.n_cobranca_parcial)) {
        this.$toast.error("Por favor, preencha o campo com o Nº de Cobrança Parcial!");
        return;
      }

      // Preparar os campos para cada evolução selecionada
      const fieldsList = this.evolucoesFiltradas.map(evolucao => ({
        id: evolucao.id,
        cobranca_parcial: true,
        n_cobranca_parcial: Number(this.currentItem.n_cobranca_parcial),
      }));

      // Chamar o método `onSubmit` para cada evolução
      fieldsList.forEach(fields => this.onSubmit(fields));
    },
    formatTimestamp() {
      if (this.currentItem.timestamp) {
        // Extrai apenas a parte da data (ignorando o fuso horário) para evitar o problema de "um dia a menos"
        const [year, month, day] = this.dateOnly.split('T')[0].split('-');

        const formattedDate = `${day}/${month}/${year}`;

        // Obtém o dia da semana sem alterar a data original
        const date = new Date(year, month - 1, day); // `month - 1` porque os meses no JavaScript são baseados em zero
        const weekDay = date.toLocaleDateString('pt-BR', { weekday: 'long' });

        return `${formattedDate} - ${weekDay.charAt(0).toUpperCase() + weekDay.slice(1)}`;
      }
      return '';
    },
    async filtrarEvolucoes() {
      if (!this.dataInicial || !this.dataFinal) {
        this.$toast.error("Por favor, selecione ambas as datas.");
        return;
      }

      this.isLoadingLocal = true; // Iniciar o carregamento

      try {
        // Carregue as evoluções antes de filtrar
        const { data } = await api.get(`atendimentos/evolucoes/?sessao=${this.currentItem.id}`);
        this.evolucao = data.filter(item => item.ativo && item.n_cobranca_parcial === null && item.timestamp_sign !== null);

        const dataInicio = new Date(this.dataInicial);
        const dataFim = new Date(this.dataFinal);

        // Adicione um dia ao dataFim para incluir evoluções no último dia
        dataFim.setDate(dataFim.getDate() + 1);

        // Aplique a lógica de filtragem
        this.evolucoesFiltradas = this.evolucao.filter(item => {
          const timestamp = new Date(item.timestamp);
          return timestamp >= dataInicio && timestamp < dataFim;
        });
      } catch (error) {
        this.$toast.error(`Desculpa, aconteceu algo errado. Erro -> ${error}`);
      } finally {
        this.isLoadingLocal = false; // Encerrar o carregamento
      }
    }
  },

  watch: {
    'currentItem.n_cobranca_parcial': function() {
      this.updateDates();
    },
    loading(newVal) {
    this.isLoadingLocal = newVal;
  }
    
  },
}
</script>
  
<style scoped>
.h {
  font-size: 16px; /* Ajuste o tamanho do texto conforme necessário */
}
.text-red {
  color: red;
}
.text-black {
  color: black;
  font-weight: bold; /* Opcional: para deixar o texto em negrito */
}


</style>

